.autocomplete-select {
    width: 100%;
    margin-bottom: 30px;
}

.create-page {
    & > div {
        overflow: inherit !important;
    }
}

.edit-page {
    & > div {
        overflow: inherit !important;
    }
}
