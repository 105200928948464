@font-face {
    font-family: "serafim-land";
    src: url("../fonts/serafim-land/font/serafim-land.eot");
    src: url("../fonts/serafim-land/font/serafim-land.eot#iefix")
            format("embedded-opentype"),
        url("../fonts/serafim-land/font/serafim-land.woff2") format("woff2"),
        url("../fonts/serafim-land/font/serafim-land.woff") format("woff"),
        url("../fonts/serafim-land/font/serafim-land.ttf") format("truetype"),
        url("../fonts/serafim-land/font/serafim-land.svg#serafim-land")
            format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'serafim-land';
    src: url('../font/serafim-land.svg?70239996#serafim-land') format('svg');
  }
}
*/

[class^="sl-"]:before,
[class*=" sl-"]:before {
    font-family: "serafim-land";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.sl-apps:before {
    content: "\e800";
} /* '' */
.sl-arrow:before {
    content: "\e801";
} /* '' */
.sl-audio:before {
    content: "\e802";
} /* '' */
.sl-broadcast:before {
    content: "\e803";
} /* '' */
.sl-calendar:before {
    content: "\e804";
} /* '' */
.sl-chevron:before {
    content: "\e805";
} /* '' */
.sl-classmate:before {
    content: "\e806";
} /* '' */
.sl-clock:before {
    content: "\e807";
} /* '' */
.sl-cross:before {
    content: "\e808";
} /* '' */
.sl-destination:before {
    content: "\e809";
} /* '' */
.sl-dome:before {
    content: "\e80a";
} /* '' */
.sl-dome-arrow:before {
    content: "\e80b";
} /* '' */
.sl-dome-smallarrow:before {
    content: "\e80c";
} /* '' */
.sl-donation:before {
    content: "\e80d";
} /* '' */
.sl-earth:before {
    content: "\e80e";
} /* '' */
.sl-eye:before {
    content: "\e80f";
} /* '' */
.sl-eye-off:before {
    content: "\e810";
} /* '' */
.sl-fb:before {
    content: "\e811";
} /* '' */
.sl-flower:before {
    content: "\e812";
} /* '' */
.sl-heart:before {
    content: "\e813";
} /* '' */
.sl-inst:before {
    content: "\e814";
} /* '' */
.sl-logo:before {
    content: "\e815";
} /* '' */
.sl-logo_norm:before {
    content: "\e816";
} /* '' */
.sl-map:before {
    content: "\e817";
} /* '' */
.sl-media:before {
    content: "\e818";
} /* '' */
.sl-play:before {
    content: "\e819";
} /* '' */
.sl-play-button:before {
    content: "\e81a";
} /* '' */
.sl-project-2:before {
    content: "\e81b";
} /* '' */
.sl-projects:before {
    content: "\e81c";
} /* '' */
.sl-radio:before {
    content: "\e81d";
} /* '' */
.sl-region:before {
    content: "\e81e";
} /* '' */
.sl-share:before {
    content: "\e81f";
} /* '' */
.sl-sheet:before {
    content: "\e820";
} /* '' */
.sl-sign-out:before {
    content: "\e822";
} /* '' */
.sl-store:before {
    content: "\e823";
} /* '' */
.sl-story:before {
    content: "\e824";
} /* '' */
.sl-twit:before {
    content: "\e825";
} /* '' */
.sl-user:before {
    content: "\e826";
} /* '' */
.sl-vk:before {
    content: "\e828";
} /* '' */

.create-page,
.edit-page {
    & > div {
        & > div {
            overflow: visible;
        }
    }
}
