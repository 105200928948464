.log-modal__body {
    background-color: white;
    padding: 20px;
    margin: 30px;
    overflow: scroll;
    height: -webkit-fill-available;

    pre {
        min-height: 90vh;
    }
}
