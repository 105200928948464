.preview {
    display: block;
    margin-top: 10px;
    padding: 10px 0;
    text-decoration: none;
    color: #3f51b5;
    font-weight: bold;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
        background-color: rgba(63, 81, 181, 0.08);
    }
}