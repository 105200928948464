.drug-crop-uploader {
    width: 30%; 
    text-align: center; 
    margin: 0 auto;
    display: flex;
    align-items: center;

    .upload-item {
        position: relative;
        display: inline-block;
        width: 250px;
        margin: 10px;
        background-color: #fff;
        padding: 16px;

        &:hover {
            cursor: pointer;
        }

        &__img {
            width: 100%;
            height: 250px;
            object-fit: cover;
        }

        &__text-input {
            width: 100%;
        }

        &__btn {
            position: absolute !important;
            top: 0;
            right: 0;
        }
    }

    .dzu-dropzone {
        width: auto;
        overflow: auto;
        min-height: auto;
        border:  none;
        margin: 0;
    }

    .dzu-inputLabel {
        position: relative;
        width: 100%;
    }

    .dropzone {
        width: 100%;
        margin: 0;
        background-color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding: 8px;
        top: 0;
        left: 0;
        background-color: #3f51b5;
        color: #fff;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.cropped-img {
    width: 100%;
    display: flex;
    justify-content: space-between;
}