.react-dropzone-s3-uploader {
    width: 20% !important;
    height: 100px !important;
    margin: 20px auto;
    background-color: #fff;

    > div {
        display: none;
    }

    &:before {
        content: "Выберите файлы";
        font-size: 25px;
        text-align: center;
        padding-top: 30px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &:hover {
        background-color: #d9fcdd;
    }
}
.droppable {
    background-color: lightgrey;
}

.upload-item {
    position: relative;
    display: inline-block;
    width: 250px;
    margin: 10px;
    background-color: #fff;
    padding: 16px;

    &:hover {
        cursor: pointer;
    }

    &__img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }

    &__text-input {
        width: 100%;
    }

    &__btn {
        position: absolute !important;
        top: 0;
        right: 0;
    }
}

.sortable-chosen {
    background-color: lightgreen !important;
}
